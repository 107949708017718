import React from 'react'
import {
  MdHome,
  MdBurstMode,
  MdLibraryBooks,
  MdComment,
  MdBusiness,
  MdOutlineEditCalendar,
} from 'react-icons/md'
import { AiFillDashboard, AiFillTags, AiOutlineLogout } from 'react-icons/ai'
import {
  FaPenNib,
  FaStickyNote,
  FaClipboardCheck,
  FaMoneyCheckAlt,
} from 'react-icons/fa'
import {
  BsFillPeopleFill,
  BsFillQuestionCircleFill,
  BsFillCollectionFill,
} from 'react-icons/bs'

const DataMenuList = [
  {
    title: 'Home',
    linkTo: '/Home',
    subLink: '/Home',
    isDropdown: -1,
    icon: <MdHome size={16} />,
    dropList: [],
  },
  {
    title: 'Dashboard',
    linkTo: '',
    isDropdown: 1,
    icon: <AiFillDashboard size={16} />,
    dropList: [
      {
        title: 'Overview',
        linkTo: '/Dashboard/Overview',
        subLink: '/Dashboard/Overview',
      },
      {
        title: 'Marketing',
        linkTo: '/Dashboard/Marketing',
        subLink: '/Dashboard/Marketing',
      },
      {
        title: 'View/Download',
        linkTo: '/Dashboard/Contents',
        subLink: '/Dashboard/Contents',
      },
      {
        title: 'app subscription',
        linkTo: '/Dashboard/Subscription',
        subLink: '/Dashboard/Subscription',
      },
    ],
  },
  {
    title: 'Blog',
    linkTo: '',
    // linkTo: "Blog/post",
    isDropdown: 2,
    // isDropdown: -1,
    icon: <MdOutlineEditCalendar size={16} />,
    dropList: [
      {
        title: 'View Posts',
        linkTo: '/Blog/view',
        subLink: '/Blog/view',
      },
      {
        title: 'Write Post',
        linkTo: '/Blog/post',
        subLink: '/Blog/post',
      },
    ],
  },
]

const ManagementMenuList = [
  {
    title: 'Banners',
    linkTo: '',
    isDropdown: 3,
    icon: <MdBurstMode size={16} />,
    dropList: [
      {
        title: 'New App',
        linkTo: '/Banner/NewApp',
        subLink: '/Banner/NewApp',
      },
      {
        title: 'New B2B',
        linkTo: '/Banner/NewB2B',
        subLink: '/Banner/NewB2B',
      },
      {
        title: 'New Home',
        linkTo: '/Banner/NewHome',
        subLink: '/Banner/NewHome',
      },
      {
        title: 'App Banners',
        linkTo: '/Banner/Mobile',
        subLink: '/Banner/Mobile',
      },
      {
        title: 'Studio Banners',
        linkTo: '/Banner/Studio',
        subLink: '/Banner/Studio',
      },
    ],
  },
  {
    title: 'Assistant',
    linkTo: '/Assistant',
    subLink: '/Assistant',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'Assistant-DEV',
    linkTo: '/AssiDev',
    subLink: '/AssiDev',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'Assistant-Edit',
    linkTo: '/AssiEdit',
    subLink: '/AssiEdit',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'Stickers',
    linkTo: '/ContentsList',
    subLink: '/ContentsList',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'AI Stickers',
    linkTo: '/AI/ContentsList',
    subLink: '/AI/ContentsList',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'Premium Stickers',
    linkTo: '/Premium/ContentsList',
    subLink: '/Premium/ContentsList',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'Bookmark Sticker',
    linkTo: '/BookmarkList',
    subLink: '/BookmarkList',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },
  {
    title: 'Creators',
    linkTo: '/Member/Artists',
    subLink: '/Member/Artists',
    isDropdown: -1,
    icon: <FaPenNib size={16} />,
    dropList: [],
  },
  {
    title: 'Users',
    linkTo: '',
    isDropdown: 4,
    icon: <BsFillPeopleFill size={16} />,
    dropList: [
      {
        title: 'Creator Data',
        linkTo: '/Member/ArtistGroupPage',
        subLink: '/Member/ArtistGroupPage',
      },
      { title: 'App Users', linkTo: '/Member/Users', subLink: '/Member/Users' },
      {
        title: 'B2B Users',
        linkTo: '/Member/CorporateUsers',
        subLink: '/Member/CorporateUsers',
      },
      {
        title: 'Creator Search',
        linkTo: '/Member/ArtistSearch',
        subLink: '/Member/ArtistSearch',
      },
    ],
  },
  {
    title: 'AI Chat Content',
    linkTo: '/AI/ChatContent',
    subLink: '/AI/ChatContent',
    isDropdown: -1,
    icon: <FaStickyNote size={16} />,
    dropList: [],
  },
  {
    title: 'Sticker Categories',
    linkTo: '/CategoryList',
    subLink: '/CategoryList',
    isDropdown: -1,
    icon: <FaStickyNote size={16} />,
    dropList: [],
  },
  {
    title: 'Message Center',
    linkTo: '',
    isDropdown: 5,
    icon: <BsFillQuestionCircleFill size={16} />,
    dropList: [
      {
        title: 'Notice',
        linkTo: '/Message/Notice',
        subLink: '/Message/Notice',
      },
      {
        title: '1:1 Message',
        linkTo: '/Message/Qna/List/1',
        subLink: '/Message/Qna',
      },
    ],
  },
  {
    title: 'App Comments',
    linkTo: '/CommentList',
    subLink: '/CommentList',
    isDropdown: -1,
    icon: <MdComment size={16} />,
    dropList: [],
  },
  {
    title: 'Guidelines',
    linkTo: '/GuideList',
    subLink: '/GuideList',
    isDropdown: -1,
    icon: <FaClipboardCheck size={16} />,
    dropList: [],
  },
  {
    title: 'Keywords',
    linkTo: '',
    isDropdown: 6,
    icon: <AiFillTags size={16} />,
    dropList: [
      {
        title: 'Untagged List',
        linkTo: '/Keyword/Untagged',
        subLink: '/Keyword/Untagged',
      },
      { title: 'Delete', linkTo: '/TagDel', subLink: '/TagDel' },
    ],
  },
  {
    title: 'Collections',
    linkTo: '',
    isDropdown: 7,
    icon: <BsFillCollectionFill size={16} />,
    dropList: [
      {
        title: 'Create',
        linkTo: '/Collection/Add',
        subLink: '/Collection/Add',
      },
      {
        title: 'Browse',
        linkTo: '/Collection/View',
        subLink: '/Collection/View',
      },
    ],
  },
  {
    title: 'Subscription',
    linkTo: '/Subscription',
    isDropdown: 8,
    icon: <BsFillPeopleFill size={16} />,
    dropList: [
      {
        title: 'Product',
        linkTo: '/Subscription/Product',
        subLink: '/Subscription/Product',
      },
      {
        title: 'Artist',
        linkTo: '/Subscription/Artist',
        subLink: '/Subscription/Artist',
      },
    ],
  },
  {
    title: 'AI',
    linkTo: '/AI',
    isDropdown: 9,
    icon: <BsFillPeopleFill size={16} />,
    dropList: [
      {
        title: 'AI Stickers',
        linkTo: '/AI/ContentsList',
        subLink: '/AI/ContentsList',
      },
      {
        title: 'AI Home',
        linkTo: '/AI/Home',
        subLink: '/AI/Home',
      },
      {
        title: 'AI Prompt',
        linkTo: '/AI/Prompt',
        subLink: '/AI/Prompt',
      },
      {
        title: 'AI Create',
        linkTo: '/AI/Create',
        subLink: '/AI/Create',
      },
      {
        title: 'AI Contest Winner',
        linkTo: '/AI/Contest',
        subLink: '/AI/Contest',
      },
      {
        title: 'AI Upload',
        linkTo: '/AI/Upload',
        subLink: '/AI/Upload',
      },
      {
        title: 'SD Prompt',
        linkTo: '/AI/SDPrompt',
        subLink: '/AI/SDPrompt',
      },
    ],
  },
  {
    title: 'Language Json',
    linkTo: '/LanguageJson',
    subLink: '/LanguageJson',
    isDropdown: -1,
    icon: <MdLibraryBooks size={16} />,
    dropList: [],
  },

]

const SettlementMenuList = [
  {
    title: '월별 정산 내역',
    linkTo: '/Money/After',
    subLink: '/Money/After',
    isDropdown: -1,
    icon: <FaMoneyCheckAlt size={16} />,
    dropList: [],
  },
  {
    title: '전체 수익 집계',
    linkTo: '/Money/Payment',
    subLink: '/Money/Payment',
    isDropdown: -1,
    icon: <FaMoneyCheckAlt size={16} />,
    dropList: [],
  },
  {
    title: '세금 정보 관리',
    linkTo: '/Money/Tax',
    subLink: '/Money/Tax',
    isDropdown: -1,
    icon: <FaMoneyCheckAlt size={16} />,
    dropList: [],
  },
  {
    title: '작가 License 관리',
    linkTo: '/Money/ArtistLicense',
    subLink: '/Money/ArtistLicense',
    isDropdown: -1,
    icon: <FaMoneyCheckAlt size={16} />,
    dropList: [],
  },
  {
    title: '작가 Paypal 관리',
    linkTo: '/Money/ArtistPaypal',
    subLink: '/Money/ArtistPaypal',
    isDropdown: -1,
    icon: <FaMoneyCheckAlt size={16} />,
    dropList: [],
  },
  {
    title: 'Tax Form 관리',
    linkTo: '/Money/TaxForm',
    subLink: '/Money/TaxForm',
    isDropdown: -1,
    icon: <FaMoneyCheckAlt size={16} />,
    dropList: [],
  },
]

const B2BMenuList = [
  {
    title: 'Channels',
    linkTo: '/AThemeList',
    subLink: '/AThemeList',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'App Data',
    linkTo: '/AppInfo',
    subLink: '/AppInfo',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'SDK Data',
    linkTo: '/SdkInfo',
    subLink: '/SdkInfo',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Search Api',
    linkTo: '/searchApi',
    subLink: '/searchApi',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Search Api New',
    linkTo: '/searchApiNew',
    subLink: '/searchApiNew',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Search Api New Fixed',
    linkTo: '/searchApiNewFixed',
    subLink: '/searchApiNewFixed',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Search Graphionica Api',
    linkTo: '/searchGraphionica',
    subLink: '/searchGraphionica ',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Search Color Api',
    linkTo: '/searchColorApi',
    subLink: '/searchColorApi',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Search History',
    linkTo: '/SearchHistory',
    subLink: '/SearchHistory',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Curation',
    linkTo: '/Curation',
    subLink: '/Curation',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Curation Preview',
    linkTo: '/Preview',
    subLink: '/Preview',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'keyword recommend',
    linkTo: '/Recommend',
    subLink: '/Recommend',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: 'Billing',
    linkTo: '/Billing',
    subLink: '/Billing',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
]

const trackingMenuList = [
  {
    title: '작가 다운로드 순위',
    linkTo: '/download',
    subLink: '/download',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '작가 등록수 순위',
    linkTo: '/regCount',
    subLink: '/regCount',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '작가 전송수 순위',
    linkTo: '/sendCount',
    subLink: '/sendCount',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '패키지 다운로드 순위',
    linkTo: '/package',
    subLink: '/package',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '패키지 뷰 순위',
    linkTo: '/view',
    subLink: '/view',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '패키지 전송수 순위',
    linkTo: '/sendSticker',
    subLink: '/sendSticker',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '국각별 패키지 수',
    linkTo: '/country',
    subLink: '/country',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '키워드 순위',
    linkTo: '/keyword',
    subLink: '/keyword',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
  {
    title: '전체 전송수',
    linkTo: '/allSend',
    subLink: '/allSend',
    isDropdown: -1,
    icon: <MdBusiness size={16} />,
    dropList: [],
  },
]

const OtherMenuList = [
  {
    title: 'Logout',
    linkTo: '/logout',
    subLink: '/User/Logout',
    isDropdown: -1,
    icon: <AiOutlineLogout size={16} />,
    dropList: [],
  },
]

export {
  DataMenuList,
  ManagementMenuList,
  SettlementMenuList,
  B2BMenuList,
  trackingMenuList,
  OtherMenuList,
}
